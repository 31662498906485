<template>
  <section id="insert-methods">
    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
        <b-row class="match-height">
          <b-col
            v-for="method in methods"
            :key="method.title"
            md="4"
            offset-md="1"
            class="pdt-card"
          >
            <b-card align="center" class="pdt-card-body">
              <div>
                <img :src="method.img" :class="method.imgClass" alt="svg img" />
                <h3>{{ $t(method.title) }}</h3>
              </div>
              <b-card-text>
                {{ $t(method.description) }}
              </b-card-text>

              <!-- <b-list-group class="list-group-circle text-left">
                <b-list-group-item v-for="(data, index) in method.benefits" :key="index">
                  {{ $t(data) }}
                </b-list-group-item>
              </b-list-group> -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                :to="{ name: method.button.href }"
                block
                class="mt-2 pdt-card-button"
                variant="primary"
              >
                {{ $t(method.button.title) }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->
    <hr />

    <div class="pricing-faq mt-3 mb-5">
      <h3 class="text-center">Συχνές Ερωτήσεις (FAQ)</h3>
      <b-row class="py-2">
        <b-col lg="10" offset-lg="2" class="mx-auto">
          <app-collapse accordion type="margin">
            <app-collapse-item
              v-for="(data, index) in qandA"
              :key="index"
              :title="data.question"
              class="faq-ques"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'monthly',
      methods: [
        {
          title: 'Υιοθετείστε ένα υπάρχον προϊόν της πλατφόρμας',
          subtitle: 'Sell Product from a wide variety',
          description:
            'Επεκτείνετε την υπάρχουσα συλλογή προϊόντων σας, μεγαλώστε το δίκτυο σας και βρείτε νέους συνεργάτες που ταιριάζουν στα θέλω σας μέσα από το MyPlaceUp!',
          img: require('@/assets/images/illustration/Pot3.svg'),
          imgClass: 'mb-2',
          button: {
            title: 'Επιλέξτε προϊόντα',
            href: 'all-products-list',
          },
        },
        {
          title: 'Εισάγετε ένα νέο προϊόν στην πλατφόρμα',
          subtitle: 'Sell your special Product',
          description: 'Εισάγετε ένα νέο προϊόν στη συλλογή σας.',
          img: require('@/assets/images/illustration/Pot1.svg'),
          imgClass: 'mb-2 mt-5',
          button: {
            title: 'Εισάγετε προϊόν',
            href: 'products-edit',
          },
        },
      ],
      qandA: [
        {
          question:
            'Πως μπορώ να υιοθετήσω ένα προϊόν που υπάρχει ήδη στην πλατφόρμα;',
          ans: 'Μέσα από το πλαίσιο “Ευκαιρίες συνεργασίας” βρείτε προϊόντα ή εταιρείες που ανταποκρίνονται στις δικές ανάγκες καθώς και αυτές των πελατών σας. Κάντε click στο κουμπί “προβολή σε e-shop” για να δείτε τα προϊόντα που σας ενδιαφέρουν και click στο κουμπί “Αποστολή Αιτήματος Συνεργασίας" για να ξεκινήσετε μια νέα συνεργασία.\nΣε περίπτωση που θέλετε να βρείτε προϊόντα για υιοθέτηση από έναν ήδη υπάρχοντα συνεργάτη, μεταβείτε στην ενότητα “Ενεργές Συνεργασίες”.',
        },
        {
          question:
            'Τι κάνω όταν θέλω να υιοθετήσω ένα νέο προϊόν αλλά δεν μπορώ να βρω τον προμηθευτή του;',
          ans: 'Επικοινωνήστε μαζί μας και αναφέρετε μας το συγκεκριμένο προϊόν ώστε να λύσουμε το πρόβλημα.',
        },
        {
          question:
            'Έχω εισάγει ένα προϊόν αλλά δεν μπορώ να το τροποποιήσω, γιατί συμβαίνει αυτό;',
          ans: 'Σε περίπτωση που το προϊόν διατίθεται από περισσότερους από έναν πωλητές ή ο προμηθευτής του είναι μέλος της πλατφόρμας, η τροποποίηση της παρουσίασης του γίνεται είτε από το MyPlaceUp είτε από τον προμηθευτή του. Σε περίπτωση που διαθέτεται υλικό όπως φωτογραφίες ή κείμενο που θα θέλατε να προστεθεί στην παρουσίαση του προϊόντος ή δεν ισχύει κάποια από τις παραπάνω περιπτώσεις, παρακαλώ επικοινωνήστε μαζί μας.',
        },
        {
          question:
            'Το προϊόν που επιθυμώ να εισάγω υπάρχει ήδη αλλά έχει λανθασμένες πληροφορίες ή είναι απομίμηση, τι κάνω;',
          ans: 'Επικοινωνήστε άμεσα μαζί μας για την επίλυση του ζητήματος.',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style scoped>
/* .pdt-card{
  width: 50%;
} */
.pdt-card-body > .card-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pdt-card-button {
  align-self: flex-end;
}
.faq-ques ::v-deep span {
  font-weight: bold;
}
</style>
